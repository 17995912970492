import { createReducer } from '../../store/utils';
import * as actionTypes from './action-types';
import {APP_CLEAR_STORE} from '../authorization/action-types';
import auction from '../../api/auction';

const initialState = {
    upcomingEvents: [],
    pastEvents: [],
    pageNumber: 1,
    pageCount: 1,
    totalRecordCount: 1,
    allUpcomingEvents: [],
    sortedEvents: [],
    loadedEvents: false,
    loadedPast: false,
    eventRegError: false,
    loadingRegisterState: true,
    registrations: [],
    eventResults: [],
    loadingResults: true,
};

export default createReducer(initialState, {
    [APP_CLEAR_STORE]: () => ({...initialState}),
    [actionTypes.LOADING_UPCOMING_EVENTS]: state => ({ ...state, loadedEvents: false }),
    [actionTypes.GET]: (state, action) => ({ ...state, upcomingEvents: [...action.payload.upcomingEvents] }),
    [actionTypes.GET_PAST]: (state, action) => ({
        ...state,
        pastEvents: [...action.payload.events],
        pageNumber: action.payload.pageNumber,
        pageCount: action.payload.pageCount,
        totalRecordCount: action.payload.totalRecordCount,
        loadedPast: true,
      }),
    [actionTypes.GET_AUCTION_RESULTS]: (state, action) => {
        return {
            ...state,
            eventResults: action.payload,
            loadingResults: false,
        }
    },
    [actionTypes.LOADING_RESULTS]: state => ({ ...state, loadingResults: true }),
    [actionTypes.GET_ALL]: (state, action) => ({ ...state, allUpcomingEvents: [...action.payload.upcomingEvents], loadedEvents: true }),
    [actionTypes.GET_UPCOMING_EVENTS_SORTED]: (state, action) => ({ ...state, sortedEvents: action.payload, loadedEvents: true }),
    [actionTypes.UPDATE_EVENT_STATE_VALUE]: (state, action) => {
        return { ...state, ...action.payload.object }
    },
    [actionTypes.SET_PAGINATION]: (state, action) => ({ ...state, ...action.payload.object }),
    [actionTypes.UPDATE_REGISTERED_EVENTS_STATE]: (state, action) => {
        const auctionEvents = state.sortedEvents.events;
        const index = auctionEvents.findIndex(event => event.event_ID === action.payload.event_ID)
        auctionEvents[index].isRegistered = !action.payload.isRegistered
        return { ...state, sortedEvents: { ...state.sortedEvents, events: [...auctionEvents] } }
    },
    [actionTypes.LOADING_REGISTERD_FOR_EVENTS]: state => ({ ...state, loadingRegisterState: false }),
    [actionTypes.REGISTER_FOR_AUCTIONS]: state => ({ ...state, loadingRegisterState: true }),
    [actionTypes.GET_REGISTERED_EVENTS]: (state, action) => ({...state, registrations:[...action.payload]}),
    [actionTypes.UPDATE_REGISTERED_STATE]: (state, action) => {
        const registeredItems = state.registrations;
        console.log(registeredItems)
        console.log(action.payload)
        registeredItems.forEach((data) => {
            if (data.event_ID === action.payload.event_ID) {
                data.register = !action.payload.register
            }
        });
        return {    
            ...state, registrations: [...registeredItems],
        }
    },
    [actionTypes.REMOVE_EVENT_REGISTRATION]: (state, action) => ({...state, registrations: [action.payload]}),
});

