import { createAuthRemoteCall } from '../../store/utils';
import * as actionTypes from './action-types';
import { logout } from '../authorization';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { updateAuctionItemSettings, updateItemState } from '../auction/index';

export const getWatchList = () => createAuthRemoteCall(
    { fetch: actionTypes.GET_WATCHLIST, loading: actionTypes.LOADING_WATCHLIST }, api => api.watchlist.getWatchList, logout)();


export const addToWatchList = (data) => async (dispatch, getState) => {

    const { auction: { item: { totalWatchers } } } = getState();

    const result = await dispatch(createAuthRemoteCall(
        { fetch: actionTypes.ADD_TO_WATCH_LIST }, api => api.watchlist.addWatchList)(data),
    );
    if (result.error) {
        toast.success(`${result.messages[0]}`);
    } else {
        toast.success('Item Added to Watchlist')
        Promise.all([
            dispatch(updateAuctionItemSettings({ watching: true })),
            dispatch(updateItemState({ totalWatchers: parseFloat(totalWatchers) + 1 }))
        ])
    }
};

export const removeFromWatchList = (data) => async (dispatch, getState) => {

    const { auction: { item: { totalWatchers } } } = getState();

    const result = await dispatch(createAuthRemoteCall(
        { fetch: actionTypes.REMOVE_FROM_WATCH_LIST }, api => api.watchlist.removeWatchList)(data),
    );

    if (result.error) {
        toast.success(`${result.messages[0]}`);
    } else {
        Promise.all([
            dispatch(updateAuctionItemSettings({ watching: false })),
            dispatch(updateItemState({ totalWatchers: parseFloat(totalWatchers) - 1 }))
        ])
        toast.success('Item Removed from Watchlist')
    }
};

