/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useLayoutEffect, useEffect, useState, lazy } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { subscribeToNewsletter, updateMainState } from "../../../reducers/main";
import {
  clearFilters,
  setCategories,
  setBoolean,
  getInventorySearchCriteria,
  getInventory,
} from "../../../reducers/search";
import persistSearch from "../../../utils/persistSearch";
import { push } from "react-router-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import TextField from "../text-field";
import { Form, FormGroup, Col, Row } from "reactstrap";
import { email } from "redux-form-validators";
import { checkForStringValue } from "../../../utils/check-filter-for-value";
import checkForCriteriaKeys from "../../../utils/check-for-criteria-keys";
import clearAllParams from "../../../utils/clear-all-params";
import useWindowSize from "../../../utils/use-window-size";

const CookieConsent = lazy(() => import("../headers/common/cookie-consent"));
const StickySearch = lazy(() => import("../search/sticky-search"));

const FooterOne = (props) => {
  const {
    featuredCategories,
    location,
    setBoolean,
    setCategories,
    push,
    persistSearch,
    subscribeToNewsletter = [],
    updateMainState,
    hasConsented,
    handleSubmit,
    pristine,
    submitting,
    emailAddress,
    reset,
    getInventorySearchCriteria,
    clearFilters,
    checkForCriteriaKeys,
    checkForStringValue,
    clearAllParams,
    getInventory,
    categoriesFilter,
    content,
  } = props;

  const [tenantName, setTenantName] = useState("");
  const [tenantPhone, setTenantPhone] = useState("");
  const [tenantEmail, setTenantEmail] = useState("");
  const [tenantAddress, setTenantAddress] = useState([]);
  const [tenantLogo, setTenantLogo] = useState("");
  const [footerTagLine, setFooterTagLine] = useState("");
  const [tenantLinks, setTenantLinks] = useState([]);
  const [tenantSocial, setTenantSocial] = useState({
    faceBook: "https://www.facebook.com",
    twitter: "https://www.twitter.com",
    instagram: "https://www.instagram.com",
    linkedIn: "https://www.linkedin.com",
  });
  const [infoLinks, setInfoLinks] = useState([]);

  const [windowWidth, windowHeight] = useWindowSize();

  useEffect(() => {
    if (content) {
      setTenantName(content.tenantName);
      setTenantPhone(content.tenantPhone);
      setTenantEmail(content.tenantEmail);
      setTenantAddress(content.tenantAddress);
      setTenantLogo(content.theme.logo2);
      setTenantLinks(content.links);
      setTenantSocial(content.socialMedia);
      setFooterTagLine(content.footerTagLine);
      setInfoLinks(content.infoLinks);
    }
  }, [content]);

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 600) {
      document.querySelector(".tap-top").style = "display: block";
    } else {
      document.querySelector(".tap-top").style = "display: none";
    }
  };

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener("scroll", logit);
    };
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  useLayoutEffect(() => {
    handleScroll();
  }, [scrollY]);

  const clickToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleSubscribe = (data) => {
    subscribeToNewsletter({
      emailAddress: data.txtEmailAddress,
    });
    reset("footerOneSubcribeForm");
  };

  let tap_to_top = { display: "none" };

  const currentYear = () => {
    var date = new Date();
    var year = date.getFullYear();
    return year;
  };

  const [navClick, setNavClick] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getInventory({
        categories: categoriesFilter.join("|"),
        page_number: 1,
      }).then(() => {
        setNavClick(false);
      });
    };
    if (navClick) {
      fetchData();
    }
  }, [navClick]);

  const handleCategoryNav = (item) => {
    if (item.id === 0) {
      Promise.all([clearFilters(), push("/search")]);
    } else {
      Promise.all([
        clearAllParams(),
        clearFilters(),
        setCategories(item.id),
        setBoolean({ id: item.id, isChecked: true }),
        persistSearch({ page_number: "1" }, "page_number"),
        persistSearch({ categories: item.value }, "categories"),
        location.pathname.includes("/search") && setNavClick(true),
      ]);
    }
  };

  const renderSearchCategories = (featuredCategories) =>
    featuredCategories.map((item) => (
      <li key={item.id}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="pointer" onClick={() => handleCategoryNav(item)}>
          {item.value}
        </a>
      </li>
    ));

  return (
    <React.Fragment>
      {!hasConsented || !hasConsented === undefined ? <CookieConsent {...{ updateMainState, hasConsented }} /> : null}
      <footer className="footer-light">
        <div className="d-xl-none col">
          <StickySearch
            {...{
              push,
              reset,
              location,
              clearFilters,
              getInventorySearchCriteria,
              checkForCriteriaKeys,
              persistSearch,
              checkForStringValue,
            }}
          />
        </div>
        <div className="cta-dark">
          {/* <div className="container">
						<section className="small-section">
							<div className="row">
								<div className="col-lg-6">
									<div className="subscribe">
										<div>
											<h4>GET THE LATEST DEALS AND MORE</h4>
											<p>Never miss anything from {tenantName} by signing up for our newsletter.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<Form
										className="form-inline subscribe-form"
										onSubmit={handleSubmit((value) => handleSubscribe(value))}>
										<FormGroup className="col-sm-8 col-md-8">
											<Field
												name="txtEmailAddress"
												component={TextField}
												placeholder="Enter you email"
												type="text"
												validate={email()}
												enableErrorText={emailAddress ? true : false}
												hasEmptyLabel={false}
											/>
										</FormGroup>
										<Col xs={{ size: 12 }} sm={4}>
											<button
												style={{ maxHeight: "60px" }}
												type="submit"
												disabled={pristine || submitting}
												className="btn btn-solid">
												subscribe
											</button>
										</Col>
									</Form>
								</div>
							</div>
						</section>
					</div> */}
        </div>
        <section className="section-b-space dark-blue-bg">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-xs-12 col-md-6 col-lg-4" style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: windowWidth > 767 ? "350px" : "none" }}>
                  <div className="footer-contant">
                    <div className="footer-logo">
                      <Link to="/">
                        <img className="img-fluid" style={{ maxWidth: 200 }} src={tenantLogo} alt={tenantName} />
                      </Link>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: footerTagLine }}></div>
                    {/* {footerTagLine} */}
                    <div className="footer-social">
                      {tenantSocial.faceBook && (
                        <a href={tenantSocial.faceBook} target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      )}

                      {tenantSocial.twitter && (
                        <a href={tenantSocial.twitter} target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      )}

                      {tenantSocial.instagram && (
                        <a href={tenantSocial.instagram} target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      )}

                      {tenantSocial.linkedIn && (
                        <a href={tenantSocial.linkedIn} target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col">
								<div className="sub-title">
									<div className="footer-title">
										<h4>SERVICES</h4>
									</div>
									<div className="footer-contant">
										<ul>
											<li><a href="" target="_blank" rel="noopener noreferrer">Transportation</a></li>
											<li><a href="" target="_blank" rel="noopener noreferrer">Financing</a></li>
											<li>
												<a
													href="/how-to-sell?inspections"
													// target="_blank"
													rel="noopener noreferrer">
													Inspections
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div> */}
              {/* <div className="col">
								<div className="sub-title">
									<div className="footer-title">
										<h4>CATEGORIES</h4>
									</div>
									<div className="footer-contant">
										<ul>{renderSearchCategories(featuredCategories)}</ul>
									</div>
								</div>
							</div> */}
              <div className="col-xs-12 col-md-6 col-lg-4" style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: windowWidth > 767 ? "350px" : "none" }}>
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>OTHER</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                        {tenantLinks
                          .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                          .map((x) => (
                            <li key={x.sequence}>
                              <Link to={`/content?${x.key}`}>{x.value}</Link>
                            </li>
                          ))}
                        <li>
                          <Link to="/terms-and-conditions">Terms & Conditions</Link>
                        </li>
                        {/* {infoLinks
													.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
													.map((x) => (
														<li key={x.sequence}>
															<Link to={`/info-center?${x.key}`}>{x.value}</Link>
														</li>
													))} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4" style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: windowWidth > 767 ? "350px" : "none" }}>
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>CONTACT US</h4>
                    </div>
                    <div className="footer-contant">
                      <ul className="contact-list">
                        <li>
                          <i className="fa fa-map-marker" />
                          {tenantAddress.map((x, i) => (
                            <div key={i}>{x}</div>
                          ))}
                        </li>
                        <li>
                          <i className="fa fa-phone" />
                          <span>{tenantPhone}</span>
                        </li>
                        <li>
                          <i className="fa fa-envelope-o" />
                          <a className="footer-email" href={`mailto:${tenantEmail}`}>
                            {tenantEmail}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <Row>
              <Col xs={12} md={6}>
                <p className="text-center">
                  <i className="fa fa-copyright" aria-hidden="true" /> {currentYear()} {tenantName} - All Rights
                  Reserved.
                </p>
              </Col>
              {/* <Col xs={12} md={6}>
								<p className="text-center">
									Powered by InnovA Technologies{" "}
									<img
										style={{ background: "#FFF", maxWidth: 100 }}
										src="https://innovastoragearea.blob.core.windows.net/logo/equipro_logo_white.png"
										alt="InnovA"
									/>
								</p>
							</Col> */}
            </Row>
          </div>
        </div>
        <div className="tap-top top-cls" onClick={() => clickToTop()} style={tap_to_top}>
          <div>
            <i className="fa fa-angle-double-up" />
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

var selector = formValueSelector("footerOneSubcribeForm");

const FooterOneSubcribeForm = reduxForm({
  form: "footerOneSubcribeForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FooterOne);

const mapStateToProps = (state) => ({
  featuredCategories: state.main.featuredCategories,
  location: state.router.location,
  hasConsented: state.main.hasConsented,
  emailAddress: selector(state, "txtEmailAddress"),
  categoriesFilter: state.search.categoriesFilter,
  content: state.authorization.content,
});

const mapDispatchToProps = {
  subscribeToNewsletter,
  clearFilters,
  setBoolean,
  setCategories,
  persistSearch,
  push,
  updateMainState,
  reset,
  getInventorySearchCriteria,
  checkForCriteriaKeys,
  checkForStringValue,
  clearAllParams,
  getInventory,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterOneSubcribeForm);
