import { createReducer } from "../../store/utils";
import * as actionTypes from "./action-types";

const initialState = {
  isStockNumberUsed: false,
};

export default createReducer(initialState, {
  [actionTypes.GET_MARKETING_QUESTIONS]: (state, action) => ({ ...state, marketingQuestionsList: action.payload }),
  [actionTypes.SAVE_MARKETING_QUESTION_RESPONSE]: (state) => ({ ...state }),
  [actionTypes.GET_STOCK_NUMBER_USED]: (state, action) => {
    return {
      ...state,
      isStockNumberUsed: action.payload.isStockNumberUsed,
    };
  },
});
