import React, { useState } from "react";
import RegisterModal from "../../account/login";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ProgressiveImage from "react-progressive-image";
import Slider from "react-slick";
import { formatDate, simpleDateFormat, localTimeAndTimeZoneFormat } from "../../../utils/common";
import { getHandOffToken } from "../../../reducers/account";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const LiveAuctionBanner = (props) => {
	const { isSignedIn, auctionBlock, handlePathChoice, getHandOffToken, isGuest } = props;
	const [signUpModal, setSignUpModal] = useState(false);

	const renderSlider =
		auctionBlock.items &&
		auctionBlock.items.map((image, idx) => (
			<div key={idx} className="img-wrapper" onLoad={() => window.dispatchEvent(new Event("resize"))}>
				<span onClick={() => handlePathChoice(image)} className="pointer">
					<ProgressiveImage
						src={image.imageUrl}
						placeholder={`${process.env.PUBLIC_URL}/assets/images/placeholder_spinner.gif`}>
						{(src, loading) => <img src={src} alt={idx} className="img-fluid" style={{ opacity: loading ? 0.8 : 1 }} />}
					</ProgressiveImage>
					<div className="description">{image.description}</div>
				</span>
			</div>
		));

	const liveauctionsettings = {
		infinite: true,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: false,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
					initialSlide: 2,
					infinite: true,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	};

	const handleEnterAuction = async () => {
		//2 IS SIMULCAST
		if (auctionBlock.eventType_ID === 2) {
			const result = await getHandOffToken();
			if (!result.error) {
				window.location.href = `${process.env.REACT_APP_SIMULCAST_BASE_URL}auction-board?token=${result}`;
			} else {
				toast.error(`Server Error Please, Please Contact Support`, {
					position: "top-right",
					auctoClose: true,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: false,
				});
			}
		} else {
      window.location.pathname = "/live-auction"
		}
	};

	return (
		<React.Fragment>
			{auctionBlock.live ? (
				<Col xs={12} className="live-auction-banner">
					<div className="banner-message-container">
						<div className="banner-wrapper">
							<div className="event-wrapper">
								<h2>{auctionBlock.descritption}</h2>
								<div className="live-auction-button">{auctionBlock.auctionStarted ? "Live" : "Prebid"}</div>
								<br />
								<span>{simpleDateFormat(auctionBlock.eventDateTime)}</span>
							</div>
							<div className="date-wrapper">
								<h3>Don't Miss Out! Starts at {localTimeAndTimeZoneFormat(auctionBlock.eventDateTime)}</h3>
								<div
									onClick={handleEnterAuction}
									// to={isSignedIn ? "/live-auction" : () => setSignUpModal(!signUpModal)}
									data-lng="en"
									className="btn btn-solid">
									{isSignedIn ? "View Inventory" : "Register Now"}
								</div>
						{/* <Link to={isSignedIn ? '/simulcast' : () => setSignUpModal(!signUpModal)}
						data-lng="en"
						className="btn btn-solid">{isSignedIn ? 'Enter Auction' : 'Register Now'}</Link> */}
							</div>
						</div>
					</div>
					<div className="list-item-wrapper">
						<Row className="category-count-wrapper  mr-1">
							{auctionBlock.categories &&
								auctionBlock.categories.map((item, idx) => (
									<Col xs={4} md={3} lg={2} key={idx} className="text-nowrap">
										{item.description}&nbsp;{" "}
										<sup>
											<span className="badge badge-pill badge-primary">{item.itemCount}</span>
										</sup>
									</Col>
								))}
						</Row>
						<Slider {...liveauctionsettings} className="slide-3">
							{renderSlider}
						</Slider>
					</div>
				</Col>
			) : null}
			{signUpModal ? <RegisterModal toggle={() => setSignUpModal(!signUpModal)} isOpen={signUpModal} /> : null}
		</React.Fragment>
	);
};

const mapStateToProps = () => ({ authorization: { isGuest } }) => ({
	isGuest,
});

const mapDispatchToProps = {
	getHandOffToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveAuctionBanner);
