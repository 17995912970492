export const GET = 'innovA-auction/event#getUpcoming';
export const GET_ALL = 'innovA/event#getAllUpcoming';
export const GET_PAST = 'innovA/event#getPastEvents';
export const GET_AUCTION_RESULTS = 'innovA/event#getAuctionResults';
export const GET_UPCOMING_EVENTS_SORTED = 'innovA/event#getUpcomingEventsSorted';
export const UPDATE_EVENT_STATE_VALUE = 'innovA/event#updateEventStateValue';
export const SET_PAGINATION = 'innovA/event#setPagination';
export const LOADING_UPCOMING_EVENTS = 'innovA/events#loadingUpcomingEvents';
export const GET_REGISTERED_EVENTS = 'innovA/events#getRegisteredEvents';
export const LOADING_REGISTERED_EVENTS = 'innovA/events#loadingRegisteredEvent';
export const UPDATE_REGISTERED_STATE = 'innovA/events#updateRegisterdState';
export const LOADING_REGISTERD_FOR_EVENTS = 'innovA/events#loadingRegisteredForEvents';
export const REGISTER_FOR_AUCTIONS = 'innovA-events#registerForAuctions';
export const UPDATE_REGISTERED_EVENTS_STATE = 'innovA/events#updateRegisteredEventsState';
export const REMOVE_EVENT_REGISTRATION = 'innovA/events#removeEventRegistration';
export const LOADING_RESULTS = 'innovA/events#loadingResults';
export const LOADING_PAST_EVENTS = 'innovA/events#loadingPastResults';
export const VALIDATE_TERMS_ACCEPTANCE_BY_EVENT_ID = 'innovA/event#ValidateTermsAcceptanceByEventId';