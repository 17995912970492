import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import HeaderOne from "./common/headers/header-one";
import FooterOne from "./common/footers/footer-one";
import { Redirect } from "react-router-dom";
import ReactHelment from "./common/react-helmet";
import { connect } from "react-redux";
import Loader from "./common/loader";
import { useIdleTimer } from "react-idle-timer";
import InactivityMonitor from "./inactivity-monitor";
import ReactGA from "react-ga4";
import { checkForReload } from "../reducers/header";
import { checkIsAuctionLive } from "../reducers/auction";
import MetaPixel from "../utils/meta/metaPixel";

const App = (props) => {
  const {
    children,
    content,
    isGuest,
    checkForReload,
    checkIsAuctionLive,
    user_ID,
    location,
    showDisclaimer,
    showDisclaimerContent,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  //INACTIVITY TIMER
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isIdle, setIsIdle] = useState(false);

  // USE THIS FOR FAVICON
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.href = process.env.REACT_APP_FAVICON;
  }, []);

  useEffect(() => {
    const hasDisclaimerBeenShown = sessionStorage.getItem(
      "hasDisclaimerBeenShown"
    );

    if (showDisclaimer && !hasDisclaimerBeenShown) {
      setIsOpen(true);
      sessionStorage.setItem("hasDisclaimerBeenShown", true);
    }
  }, [showDisclaimer]);

  useEffect(() => {
    // setIsLoggedIn(!isGuest);
    reset();
  }, [isGuest]);

  const handleOnIdle = () => {
    if (!isGuest) {
      pause();
      setIsIdle(true);
    }
  };

  const handleOnActive = (event) => {
    setIsIdle(false);
    // console.log("on active", event);
  };

  const handleOnAction = (event) => {
    setIsIdle(false);
    // console.log("on action", event);
  };

  const { reset, pause } = useIdleTimer({
    timeout: content && content.inactivityMilliseconds,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    // stopOnIdle: isLoggedIn,
    debounce: 500,
  });

  useEffect(() => {
    const fetchData = async () => {
      await checkForReload();
    };

    if (user_ID) {
      fetchData();
    }
  }, [user_ID]);

  useEffect(() => {
    const fetchData = async () => {
      await checkIsAuctionLive({ Event_ID: 0 });
    };

    if (user_ID) {
      fetchData();
    }
  }, [user_ID, location.pathname]);

  useEffect(() => {
    if (content) {
      new Promise((resolve, reject) => {
        document.documentElement.style.setProperty("--primary-color", content.theme.primaryColor);
        document.documentElement.style.setProperty("--secondary-color", content.theme.secondaryColor);
        document.documentElement.style.setProperty("--text-color1", content.theme.textColor1);
        document.documentElement.style.setProperty("--text-color2", content.theme.textColor2);
        document.documentElement.style.setProperty("--text-color3", content.theme.textColor3);
        document.documentElement.style.setProperty("--text-color4", content.theme.textColor4);
        document.documentElement.style.setProperty("--header-menu-bg-color", content.theme.headerMenuBackgroundColor);
        document.documentElement.style.setProperty("--header-menu-text-color", content.theme.headerMenuTextColor);

        resolve();
      })
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [content]);

  useEffect(() => {
    if (content) {
      if (content?.innovaGoogleAnalytics_ID && content?.clientGoogleAnalytics_ID) {
        ReactGA.initialize([
          {
            trackingId: content?.innovaGoogleAnalytics_ID,
          },
          {
            trackingId: content?.clientGoogleAnalytics_ID,
          },
        ]);
      }
      if (content?.innovaGoogleAnalytics_ID) {
        ReactGA.initialize(content?.innovaGoogleAnalytics_ID);
      }
      if (content?.clientGoogleAnalytics_ID) {
        ReactGA.initialize(content?.clientGoogleAnalytics_ID);
      }
    }
  }, [content]);

  useEffect(() => {
    if (content) {
      setIsContentLoaded(true);
    }
  }, [content])

  if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.userAgent.indexOf("Trident/") > -1) {
    return <Redirect to="/update-browser" />;
  }

  return (
    <React.Fragment>
        {isContentLoaded && <MetaPixel clientMetaPixelID={content.clientMetaPixelID} innovaMetaPixelID={content.innovaMetaPixelID}/>}
        {isLoading && <Loader />}
        <ReactHelment />
        <HeaderOne />
        {children}
        <div>
          <br />
        </div>
        <ToastContainer />
        <FooterOne />
        {isIdle && <InactivityMonitor reset={reset} />}
        <Modal
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={() => setIsOpen(!isOpen)}>Disclaimer</ModalHeader>
          <ModalBody>
            <div style={{fontSize: "larger"}}>{showDisclaimerContent}</div>
          </ModalBody>
        </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = () => ({
  router: { location },
  authorization: {
    content,
    isGuest,
    user_ID,
    showDisclaimer,
    showDisclaimerContent,
  },
}) => ({
  location,
  content,
  isGuest,
  user_ID,
  showDisclaimer,
  showDisclaimerContent,
});

const mapDispatchToProps = {
  checkIsAuctionLive,
  checkForReload,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
