import { get, create } from './common';

export default {
    getItem: async (params, token) => get(`/v1/Auction/GetItem?ID=${params.id}&lat=${params.lat}&lon=${params.lon}`, token),
    getPhotoGallery: async (params, token) => get(`/v1/Auction/GetPhotoGallery?item_ID=${params.item_ID}`, token),
    preBid: async (params, token) => create(`/v1/Auction/PreBid`, params, token),
    buyNow: async (params, token) => create(`/v1/Auction/BuyNow`, params, token),
    makeOffer: async (params, token) => create(`/v1/Auction/makeOffer`, params, token),
    getItemInspection: async (params, token) => get(`/v1/Auction/GetItemInspection?item_ID=${params.item_ID}`, token),
    getItemInspectionReport: async (params, token) => get(`/v1/Auction/GetItemInspectionPDF_v2?Item_ID=${params.Item_ID}`, token),
    getInspectionReport: async (params, token) => get(`/v1/Auction/GetInspectionReport?Item_ID=${params.Item_ID}`, token),
		checkIsAuctionLive: async (params, token) => get(`/v1/Auction/IsAuctionLive?Event_ID=${params.Event_ID}`, token),
		getHandoffToken: async (params, token) => get(`/v1/Auction/GetHandoffSession?Event_ID=${params.Event_ID}`, token),
    addTaxExemption: async (params, token) => create(`/v1/Auction/AddTaxExemption`, params, token, true),
    getTermsAndConditions: async (params, token) => get(`/v1/Auction/getTermsAndConditions`, token),
    updateUserTermsAcceptance: async (params, token) => create(`/v1/Auction/updateUserTermsAcceptance`,params, token),
}