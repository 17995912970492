import React, { lazy, useEffect, useState } from "react";
import "../../common/index.scss";
import {
  setFilteredItem,
  setFilteredDates,
  setFilteredIDs,
  clearFilters,
  getInventorySearchCriteria,
} from "../../../reducers/search";
import { checkForStringValue } from "../../../utils/check-filter-for-value";
import {
  getUpcomingAuctionsPreview,
  getRecentItems,
  getAuctionBlock,
} from "../../../reducers/main";
import { getWatchList } from "../../../reducers/watchlist";
import { getUpcomingEventsSorted } from "../../../reducers/events";
import { getUserInformation } from "../../../reducers/account";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { checkForLiveAuction } from "../../../utils/check-for-live-auction";
import { Container } from "reactstrap";
import RecentlyViewed from "./recently-viewed";
import checkForCriteriaKeys from "../../../utils/check-for-criteria-keys";
import handlePathChoice from "../../../utils/handle-path-choice";
import LiveAuctionBanner from "./live-auction-banner";
import { updateRegisterState } from "../../../reducers/register";

const Tabs = lazy(() => import("../../common/tabs"));
const UpcommingAuctions = lazy(() => import("./upcoming-auctions"));
const Cta = lazy(() => import("./cta"));
const LoginRegister = lazy(() => import("../../account/login"));

const Home = (props) => {
  const {
    getRecentItems,
    getUpcomingAuctionsPreview,
    getWatchList,
    isSignedIn,
    upcoming,
    push,
    loaded_upcoming,
    recentlyViewed,
    getAuctionBlock,
    auctionBlock,
    setFilteredDates,
    setFilteredIDs,
    clearFilters,
    getInventorySearchCriteria,
    checkForCriteriaKeys,
    handlePathChoice,
    getUserInformation,
    getUpcomingEventsSorted,
    content,
    location,
    updateRegisterState,
  } = props;

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getUpcomingAuctionsPreview();
      await getWatchList();
      await getRecentItems();
      await getUpcomingEventsSorted({ pageNumber: 1 });
      await getUserInformation();
      await getAuctionBlock();
    };
    fetchData();
  }, []);

  useEffect(() => {
    let searchParams = {};
    const search = location.search.replace("?", "").split("&");

    search.forEach((x) => {
      if (x.includes("=")) {
        let arr = x.split("=");
        searchParams = {
          ...searchParams,
          [arr[0]]: arr[1],
        };
      }
    });

    if (searchParams.code) {
      setIsPopUpOpen(prevState => !prevState);
      updateRegisterState({
        passwordRequest: true,
      });
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <section className="position-relative home-page-container">
        {content && content.theme.heroImage ? (
          <div
            className="header-container"
            style={{ backgroundImage: `url(${content.theme.heroImage})` }}
          ></div>
        ) : (
          <div className="header-container"></div>
        )}

        <Tabs
          {...{
            clearFilters,
            getInventorySearchCriteria,
            checkForCriteriaKeys,
            content,
          }}
        />
      </section>

      <section className="home">
        <Container>
          {/* {checkForLiveAuction() && < LiveAuctionBanner {...{ isSignedIn, auctionBlock, push }} />} */}
          {auctionBlock.items && auctionBlock.items.length >= 5 && (
            <LiveAuctionBanner
              {...{ isSignedIn, auctionBlock, push, handlePathChoice }}
            />
          )}
        </Container>
      </section>

      <section>
        <UpcommingAuctions
          {...{
            upcoming,
            push,
            loaded_upcoming,
            setFilteredDates,
            setFilteredIDs,
            clearFilters,
            handlePathChoice,
          }}
        />
      </section>

      <section>
        <Cta {...{ handlePathChoice, content }} />
      </section>

      {recentlyViewed.length > 1 ? (
        <div className="container">
          <section className="margin-top-25">
            <RecentlyViewed {...{ recentlyViewed, handlePathChoice }} />
          </section>
        </div>
      ) : (
        ""
      )}
      {isPopUpOpen && (
        <LoginRegister
          toggle={() => setIsPopUpOpen(!isPopUpOpen)}
          isOpen={isPopUpOpen}
        />
      )}
      <div className="flex-1"></div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.authorization.status,
  categories: state.main.categories,
  recentlyViewed: state.main.recentlyViewed,
  upcoming: state.main.upcomingEvents,
  loaded_upcoming: state.main.loaded_upcoming,
  history: state.router,
  auctionBlock: state.main.upcomingAuctionBlock,
  content: state.authorization.content,
  location: state.router.location,
});

const mapDispatchToProps = {
  setFilteredItem,
  getRecentItems,
  getUpcomingAuctionsPreview,
  push,
  checkForStringValue,
  getWatchList,
  checkForLiveAuction,
  getAuctionBlock,
  setFilteredDates,
  setFilteredIDs,
  clearFilters,
  getInventorySearchCriteria,
  checkForCriteriaKeys,
  handlePathChoice,
  getUserInformation,
  getUpcomingEventsSorted,
  updateRegisterState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
