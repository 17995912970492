import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ReactGA from "react-ga";

const ReactHelmetComponent = ({ pathname, content }) => {
  const [tenantName, setTenantName] = useState("");

  ReactGA.initialize("G-X7QZ2HFZ1Y");
  // ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    if (content) {
      setTenantName(content.tenantName);
    }
  }, [content]);

  const [metaDataValue, setMetadataValue] = useState({
    title: null,
    description: null,
    keywords: null,
  });

  const handleTitle = (pathname) => {
    switch (pathname) {
      case "/":
        return setMetadataValue({
          title: tenantName,
          description: "On-line auctions or buy now. Simplifying the way you buy and sell equipment with a platform built for convenience, speed and consumer confidence.",
          keywords: "Heavy equipment auctions, heavy equipment auction, equipment auction, equipment auctions, used construction equipment, truck auctions, construction equipment auction",
        });
      case "/how-to-buy":
        return setMetadataValue({
          title: "Online Auctions How To Buy",
          description: "Click here to learn a simpler way to buy the equipment you need to succeed. Save time, money and frustration with our customer focused auctions.",
          keywords: "Buying used equipment, Buying used construction equipment, Buying used trucks",
        });
      case "/how-to-sell":
        return setMetadataValue({
          title: "Online Auctions How To Sell",
          description: "Click here to learn about our simple process, built around you so you can sell your heavy equipment for the best price. It's never been easier!",
          keywords: "Selling heavy equipment, Selling construction equipment",
        });
      case "/search":
        return setMetadataValue({
          title: "Equipment For Sale",
          descriptions: "Click here to search our huge selection of Trucks, Heavy equipment, Construction equipment, Cranes, Trailers, Attachments and Agriculture equipment.",
          keywords: "Used trucks for sale, Heavy equipment, Construction Equipment, Cranes for sale, Farm equipment",
        });
      case "/online-auctions":
        return setMetadataValue({
          title: "Upcoming Equipment Auctions",
          descriptions: "Click here to find our next online equipment auction. We host regular online auctions each month. Simplifying the way equipment is bought and sold.",
          keywords: "Heavy equipment auctions, Construction auctions, Online equipment auctions, Truck auctions",
        });
      case "/info-center":
        return setMetadataValue({
          title: "Help Center and FAQs",
          descriptions: "Have questions about how our online auctions work or how to buy equipment? Our easy to use help center will have all the answers.",
          keywords: "How do online auctions work",
        });
      default:
        return setMetadataValue({
          title: "Equipment Auctions",
          description: "On-line auctions or buy now. Simplifying the way you buy and sell equipment with a platform built for convenience, speed and consumer confidence.",
        });
    }
  };

  useEffect(() => {
    handleTitle(pathname);
  }, [pathname]);

  return (
    <Helmet>
      <title>{metaDataValue.title}</title>
      <meta name="description" content={metaDataValue.description} />
      <meta name="keywords" content={metaDataValue.keywords} />
    </Helmet>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  content: state.authorization.content,
});

export default connect(mapStateToProps, null)(ReactHelmetComponent);
