/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unassigned-import */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js';
import 'core-js/features/array/includes';
import './scripts/polyfill';

import 'filepond-polyfill';
import 'browser-polyfills';

import React, { Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import './index.scss';
import { Route, Switch, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router';
import Layout from './components/app';
import Loader from './components/common/loader';
import Auction from './components/layouts/auction';
import { persistStore } from 'redux-persist';
import storeCreator from './store';
import App from './components/app';
import { PersistGate } from 'redux-persist/integration/react';

const Cart = lazy(() => import('./components/cart'));
const PageNotFound = lazy(() => import('./components/pages/404'));
const Unauthorized = lazy(() => import('./components/pages/error'));
const Item = lazy(() => import('./components/auction-item'));
const LiveAuction = lazy(() => import('./components/live-auction'));
const SignalRSimulcast = lazy(() => import('./components/simulcast'));
const Search = lazy(() => import('./components/search'));
const CreatePassword = lazy(() => import('./components/account/login/create-password'));
const OfferRejected = lazy(() => import('./components/common/purchase/offer-reject'));
const OfferAccepted = lazy(() => import('./components/common/purchase/offer-confirmation'));
const UserAccount = lazy(() => import('./components/account'));
const Register = lazy(() => import('./components/account/register'));
const Notifications = lazy(() => import('./components/notifications'))
const AllAuctions = lazy(() => import('./components/auction/all-auctions'));
// const UpcomingAuctions = lazy(() => import('./components/auction/upcoming-auctions'));
const PastAuctions = lazy(() => import('./components/auction/past-auctions'));
const EventResults = lazy(() => import('./components/auction/event-results'));
const Compare = lazy(() => import('./components/compare'));
const AuctionSummary = lazy(() => import('./components/auction-summary'));
const Help = lazy(() => import('./components/help'));
const BrowserDetect = lazy(() => import('./components/browser-detect'));
const RegisterSuccess = lazy(() => import('./components/account/register/register-success'));
const CreateAccountSuccess = lazy(() => import('./components/account/login/create-account-success'));
const Content = lazy(() => import('./components/content'));
const Verification = lazy(() => import('./components/verification'));
const EnterAuction = lazy(() => import('./components/enter-auction'));
const ActivateInspector = lazy(() => import('./components/activate-inspector'));
const TermsAndConditions = lazy(() => import('./components/terms-and-conditions'));

const history = createBrowserHistory();
const store = storeCreator(history);
const persistor = persistStore(store);
const rootElement = document.getElementById('root');

// eslint-disable-next-line no-unused-vars
const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<Suspense fallback={<div><Loader /></div>}>
						<Switch>
							<Route path={'/update-browser'} render={(props) => <BrowserDetect {...props} />} />
							<Route exact path={'/activate/inspector'} render={() => <ActivateInspector />} />
							<Route exact path={'/welcome/:resetToken'} render={(props) => <CreatePassword {...props} />} />
							<Route exact path={'/welcome/:resetToken/:callbackURL'} render={(props) => <CreatePassword {...props} />} />
							<Route exact path={'/reset/:resetToken'} render={(props) => <CreatePassword {...props} reset={true} />} />
							<Route exact path={'/reset/:resetToken/:callbackURL'} render={(props) => <CreatePassword {...props} reset={true} />} />
							<Route exact path={`/`} render={() => <Layout><Auction /></Layout>} />
							<Route path={`/search`} render={() => <Layout><Search /></Layout>} />
							<Route exact path={`/item/:category/:family/:year/:make/:model/:itemId`} render={(props) => <Layout><Item {...props} /></Layout>} />
							<Route exact path={`/item/:category/:family/:seoDescription/:itemId`} render={(props) => <Layout><Item {...props} /></Layout>} />
							<Route exact path={`/item/:itemId`} render={(props) => <Layout><Item {...props} key={Math.random()} /></Layout>} />
							<Route path={`/cart`} render={() => <Layout><Cart /></Layout>} />
							<Route path={`/live-auction`} render={() => <Layout><LiveAuction /></Layout>} />
							<Route path={`/simulcast`} render={() => <Layout><SignalRSimulcast /></Layout>} />
							{/* <Route path={`/online-auctions`} render={() => <Layout><AllAuctions /></Layout>} /> */}
							<Route path={`/upcoming-auctions`} render={() => <Layout><AllAuctions /></Layout>} />
							<Route path={`/past-auctions`} render={() => <Layout><PastAuctions /></Layout>} />
							<Route path={`/event/results/:eventID`} render={(props) => <Layout><EventResults  {...props} /></Layout>} />
							<Route path={'/offer-received/:itemId'} render={(props) => <Layout><OfferAccepted {...props} /></Layout>} />
							<Route path={'/offer-made/:itemId'} render={(props) => <Layout><OfferRejected {...props} /></Layout>} />
							<Route path={'/myaccount'} render={() => <Layout><UserAccount /></Layout>} /> 
							<Route path={'/register'} render={() => <Layout><Register /></Layout>} />
							<Route path={'/notifications'} render={() => <Layout><Notifications /></Layout>} />
							<Route path={'/compare'} render={() => <Layout><Compare /></Layout>} />
							<Route path={'/auction-summary'} render={() => <Layout><AuctionSummary /></Layout>} />
							<Route path={'/info-center'} render={() => <Layout><Help /></Layout>} />
							<Route path={'/error'} render={() => <Layout><Unauthorized /></Layout>} />
							<Route path={'/success'} render={() => <Layout><RegisterSuccess /></Layout>} />
							<Route path={'/content'} render={() => <Layout><Content /></Layout>} />
							<Route path={'/verification'} render={() => <Layout><Verification /></Layout>} />
              				<Route path={'/terms-and-conditions'} render={() => <Layout><TermsAndConditions /></Layout>} />
							<Route exact path={'/welcome-to-auctions'} render={() => <Layout><CreateAccountSuccess /></Layout>} />
							<Route path={'/enter-auction'} render={() => <Layout><EnterAuction /></Layout>} />
							<Route render={() => <Layout><PageNotFound/></Layout>} />
							<Redirect to="/" />
						</Switch>
					</Suspense>
				</ConnectedRouter>
			</PersistGate>
		</Provider>,
		rootElement,
	);
}

render(App);

if (module.hot) {
	module.hot.accept("./components/app", () => {
		const NextApp = require("./components/app").default;
		render(NextApp);
	});
}
