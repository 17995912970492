import * as actionTypes from './action-types';

export const loadingLiveAuction = () => ({type: actionTypes.LOADING_INITIALIZE_AUCTION});

export const initializeLiveAuction = (event) => ({
    type: actionTypes.INITIALIZE_LIVE_AUCTION,
    payload: event,
});

export const refreshAuction = (event) => ({
    type: actionTypes.REFRESH_LIVE_AUCTION,
    payload: event,
});
        
export const updateAuctionItem = item => ({
    type: actionTypes.UPDATE_AUCTION_ITEM,
    item,
});

export const swapAuctionItem = items => ({
  type: actionTypes.SWAP_AUCTION_ITEM,
  items,
});

export const updateAuctionItemOutbid = item => ({
    type: actionTypes.UPDATE_AUCTION_ITEM_OUTBID,
    item,
});

export const updateAuctionItemRemove = item => ({
    type: actionTypes.UPDATE_AUCTION_ITEM_REMOVE,
    item,
});

export const updateAuctionItemTimer = items => ({
    type: actionTypes.UPDATE_AUCTION_ITEM_TIMER,
    items,
});

export const setAuctionBlockSize = auctionBlockSize => ({
    type: actionTypes.SET_AUCTION_BLOCK_SIZE,
    payload: { object: auctionBlockSize },
});

export const setServerTime = serverTime => ({
    type: actionTypes.SET_SERVER_TIME,
    payload: { object: serverTime },
});

export const updateInstabid = (item, callback) =>{
    if (callback) {
        callback()
    }
    return {
        type: actionTypes.UPDATE_INSTA_BID,
        payload: { object: item },
    }
}

export const updateItemsBidOn = item => ({
    type: actionTypes.UPDATE_ITEMS_BID_ON,
    payload: item,
});

export const updateBuyingPower = item => ({
    type: actionTypes.UPDATE_BUYING_POWER,
    payload: item,
});

export const updateAuctionState = item => ({
    type: actionTypes.UPDATE_AUCTION_STATE,
    payload: {object: item}
});
