import { create } from './common';

export default {
    login: async params => create('/Token/v2', {
        grant_Type: 'password',
        user_Name: params.username,
        password: params.password,
        route_ID: 2,
    }),
    refresh: async params => create('/Token/v2', {
        grant_Type: 'refresh',
        user_Name: params.refresh_token,
    }),
    proxy: async params => create('/Token/v2', {
        grant_Type: 'proxy',
        user_Name: params ? params.user_ID : '',
        password: process.env.REACT_APP_SECRET_KEY,
        proxy_ID: process.env.REACT_APP_SERVICE_ID,
    }),
};